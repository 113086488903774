import React, { useContext, useEffect, useState } from 'react';
import completeIcon from '../img/icons/calendar.svg';
import gameIcon from '../img/icons/game.svg';
import quizIcon from '../img/icons/quiz.svg';
import { calendarData, calendarDataMobile } from './calendarData';
import Modal from "./Modal";
import logo from "../img/logo.svg";
import { Link } from "react-router-dom";
import closeIcon from "../img/icons/close.svg";
import { getUserData } from '../utils/helper';
import UserContext from '../utils/userContext';

const Calendar = ({ userData, setUserData }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [openActive, setOpenActive] = useState(null);
    const [loading, setLoading] = useState(false);
    const [gameContent, setGameContent] = useState('');
    const { userId } = useContext(UserContext);
    const sortDesktop = (a, b) => {
        const sortingArr = [13,9,18,5,6,22,7,16,2,24,17,12,1,20,15,11,3,8,19,14,21,4,23,10];
        return sortingArr.indexOf(a.day) - sortingArr.indexOf(b.day);
    };
    const sortMobile = (a, b) => {
        const sortingArr = [13,9,18,15,5,12,22,8,11,19,4,1,21,14,6,23,3,7,20,16,2,10,24,17];
        return sortingArr.indexOf(a.day) - sortingArr.indexOf(b.day);
    };
    const date = new Date().getDate();
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        if (!document.body.classList.contains('calendar-page')) {
            document.body.classList.add('calendar-page')
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            if (document.body.classList.contains('calendar-page')) {
                document.body.classList.remove('calendar-page')
            }
        };
    }, []);

    const handleOpenTile = (day) => {
        if (!openActive) {
            setOpenActive(day);
        }
        if (openActive === day) {
            setOpenActive(null);
        } else {
            setOpenActive(day);
        }
    };

    const closePopup = (item) => {
        setLoading(false);
        setOpenActive(null);
        setGameContent('');
        if (item.gameType && item.gameType === 'quiz') {
            getUserData(userId)
                .then(data => {
                    if(data && data.length > 0) {
                        setUserData(data)
                    }
                })
        }
    }

    let loaderContent = (
        <>
            <div className="text-center mx-auto">
                {gameContent
                    ? (<>
                        {gameContent}
                    </>)
                    : (<>
                        <Link to="/" className="inline-block mx-auto logo mb-4">
                            <img src={logo} alt="logo" className="w-[120px]" />
                        </Link>
                        <div className={`loading mx-auto rounded-[20px] bg-[rgba(0,0,0,0.5)] h-[6px] w-[120px]`}>
                            <div className="progress-bar rounded-[20px] h-[6px] w-[120px]" />
                        </div>
                    </>)
                }
            </div>
            <div className="pt-24"/>
        </>
    )

    const handleLoadGame = (game, item) => {
        const gameData = `tournament_id=${item.day}&game_id=${game}&user_id=${userId}`;
        const quizData = item.gameType && item.gameType === 'quiz' ? `?q=${item.quizQuestion}&env=uat&` : '?env=uat&';

        if (isMobile && game === '07') {
            window.open(`${process.env.PUBLIC_URL}/games/${game}/index.html${quizData}${userId !== '' ? gameData : ''}`, '_blank', 'noreferrer');
            setOpenActive(null);
        } else {
            setLoading(true);
            setGameContent(<div className="game-container p-[4px]">
                <Link onClick={() => closePopup(item)} className="btn btn-header absolute top-0 right-0 z-[1]">
                    <span><img src={closeIcon} className="w-6 h-6" alt="close icon" /></span>
                </Link>
                <iframe allowFullScreen={true} frameBorder={'0'} src={`${process.env.PUBLIC_URL}/games/${game}/index.html${quizData}${userId !== '' ? gameData : ''}`} className="mx-auto bg-white" />
            </div>);
        }
        console.log(`${process.env.PUBLIC_URL}/games/${game}/index.html${quizData}${userId !== '' ? gameData : ''}`);
    };

    const renderCalendar = () => {
        if (!calendarData?.data?.length) {
            return null;
        }
        const dates = calendarData.data.sort(sortDesktop);
        const datesMobile = calendarDataMobile.data.sort(sortMobile);

        return (
            <>
                <div className="grid-cols-2 gap-[2px] w-[992px] mx-auto hidden xl:grid">
                    <div>
                        <div className="grid grid-cols-5 gap-[2px] mb-[2px]">
                            {dates.slice(0, 12).map((item, i) => {
                                return (<React.Fragment key={i}>
                                    {renderCell(item)}
                                </React.Fragment>)
                            })}
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-5 w-[496px] mx-auto gap-[2px] mb-[2px]">
                            {dates.slice(12, 24).map((item, i) => {
                                return (<React.Fragment key={i}>
                                    {renderCell(item)}
                                </React.Fragment>)
                            })}
                        </div>
                    </div>
                </div>
                <div className="w-[342px] mx-auto xl:hidden mobile-calendar">
                    <div className="grid grid-cols-4 gap-[2px] mb-[2px]">
                        {datesMobile.slice(0, 6).map((item, i) => {
                            return (<React.Fragment key={i}>
                                {renderCell(item, true)}
                            </React.Fragment>)
                        })}
                    </div>
                    <div className="grid grid-cols-4 gap-[2px] mb-[2px] mt-[-89px]">
                        {datesMobile.slice(6, 12).map((item, i) => {
                            return (<React.Fragment key={i}>
                                {renderCell(item, true)}
                            </React.Fragment>)
                        })}
                    </div>
                    <div className="grid grid-cols-4 gap-[2px] mb-[2px] mt-[-87px]">
                        {datesMobile.slice(12, 18).map((item, i) => {
                            return (<React.Fragment key={i}>
                                {renderCell(item, true)}
                            </React.Fragment>)
                        })}
                    </div>
                    <div className="grid grid-cols-4 gap-[2px] mb-[2px] mt-[-89px]">
                        {datesMobile.slice(18, 24).map((item, i) => {
                            return (<React.Fragment key={i}>
                                {renderCell(item, true)}
                            </React.Fragment>)
                        })}
                    </div>
                </div>
            </>
        )
    };

    const renderCell = (item, mobile) => {
        let height = item.cellType === 'small' || item.cellType === 'wide' ? 'h-24' : 'h-48 row-span-2';
        const columnClass = item.cellType === 'small' || item.cellType === 'tall' ? 'col-span-1' : '';
        const isQuiz = item.gameType && item.gameType === 'quiz';
        // let active = item.day === date;
        let active = true;
        let statusCompleted = false;

        if (date >= item.day && userData) {
            const todayPlaying = userData.filter(el => el.tournament_id === `${item.day}`);
            if (todayPlaying.length > 0 && todayPlaying.find(el => el.is_valid)) {
                //
                if (active) {
                    active = false;
                }

                statusCompleted = true;
            }
        }

        if (item.gameType && item.gameType === 'quiz') {
            if (active && statusCompleted) {
                active = false;
            }
        } else if (date === item.day) {
            //
            active = true;

            statusCompleted = false;
        }

        if (mobile) {
            height = item.cellType === 'small' || item.cellType === 'wide' ? 'h-[83px]' : 'h-[170px]';
        }

        if (active) {
            height = height + ' active';
        }
        if (statusCompleted) {
            height = height + ' completed';
        }

        return (
            <div className={`tile ${height} ${columnClass} ${mobile ? ' day-' +  item.day : ''}${openActive === item.day ? ' open' : ''}`}>
                <div
                    className="cover"
                    onClick={active ? () => handleOpenTile(item.day) : null}

                >
                    <span className="day">{item.day}</span>
                    {statusCompleted || active
                        ? (<svg xmlns="http://www.w3.org/2000/svg" width="193" height="28" viewBox="0 0 193 28" fill="none">
                            <g filter="url(#filter0_f_2247_30)"><path d="M2 11.1636L191 2V26L2 12.6711V11.1636Z" fill="url(#paint0_linear_2247_30)"/>
                            </g>
                            <defs>
                                <filter id="filter0_f_2247_30" x="0" y="0" width="193" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_2247_30"/>
                                </filter>
                                <linearGradient id="paint0_linear_2247_30" x1="191" y1="2" x2="2" y2="2" gradientUnits="userSpaceOnUse">
                                    <stop stopOpacity="0.3"/>
                                    <stop offset="0.2" stopOpacity="0.6"/>
                                    <stop offset="0.4" stopOpacity="0.9"/>
                                    <stop offset="1" stopOpacity="0.9"/>
                                </linearGradient>
                            </defs>
                        </svg>)
                        : null}
                </div>
                {statusCompleted
                    ? (<div className="title-page">
                        <img src={completeIcon} alt='icon' />
                        <span>Well done!</span>
                    </div>)
                    : null}
                {active
                    ? (<button className="title-page" onClick={() => handleLoadGame(item.game, item)}>
                        <img src={isQuiz ? quizIcon : gameIcon} alt='icon' />
                        <span>{isQuiz ? 'Start quiz' : 'Play now'}</span>
                    </button>)
                    : null}
            </div>
        )
    };

    return (
        <>
            <main>
                <div className="container text-center pt-4 mb-[-32px] xl:py-8 xl:mb-0">
                    {renderCalendar()}
                </div>
            </main>
            <Modal
                showModal={loading}
                setShowModal={setLoading}
                classes={'modal-loader'}
                content={loaderContent}
            />
        </>
    );
};

export default Calendar;
