import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../img/logo.png';
import backIcon from '../img/icons/back.svg';
import menuIcon from '../img/icons/menu.svg';
import Modal from "./Modal";
import Footer from "./Footer";

const Header = ({ isExpired }) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const modalContent = (
        <>
            <main>
                <ul className="menu text-center">
                    <li>
                        <Link to="/" onClick={() => setShowModal(false)}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/calendar" onClick={() => setShowModal(false)}>
                            Calendar
                        </Link>
                    </li>
                    <li>
                        <Link to="/results" onClick={() => setShowModal(false)}>
                            Results
                        </Link>
                    </li>
                </ul>
            </main>
            <Footer />
        </>
    )

    return (
        <>
            <header className="header text-center">
                {isExpired ? (
                    <div className="container flex items-center justify-between py-8">
                        <Link to="/" className="mx-auto logo">
                            <img src={logo} alt="logo" className="max-w-[104px] mr-[-22px] mt-[-10px]"/>
                        </Link>
                    </div>
                ) : (
                    <div className="container flex items-center justify-between py-8">
                        <Link onClick={() => navigate(-1)} className="btn btn-header">
                            <span><img src={backIcon} className="w-6 h-6" alt="back icon" /></span>
                        </Link>
                        <Link to="/" className="mx-auto logo">
                            <img src={logo} alt="logo" className="max-w-[104px] mr-[-22px] mt-[-10px]"/>
                        </Link>
                        <Link onClick={() => setShowModal(true)} className="btn btn-header">
                            <span><img src={menuIcon} className="w-6 h-6" alt="menu icon" /></span>
                        </Link>
                    </div>
                )}
            </header>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                classes={'modal-menu'}
                content={modalContent}
            />
        </>
    );
};

export default Header;
