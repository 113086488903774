export const calendarData = {
    data: [
        {
            day: 1,
            status: '',
            cellType: '',
            game: '01',
        },
        {
            day: 2,
            status: '',
            cellType: '',
            game: '02',
        },
        {
            day: 3,
            status: '',
            cellType: '',
            gameType: 'quiz',
            quizQuestion: 1,
            game: '03',
        },
        {
            day: 4,
            status: '',
            cellType: '',
            game: '04',
        },
        {
            day: 5,
            status: '',
            cellType: 'tall',
            game: '05',
        },
        {
            day: 6,
            status: '',
            cellType: '',
            gameType: 'quiz',
            quizQuestion: 2,
            game: '03',
        },
        {
            day: 7,
            status: '',
            cellType: 'small',
            game: '07',
        },
        {
            day: 8,
            status: '',
            cellType: 'wide',
            game: '08',
        },
        {
            day: 9,
            status: '',
            cellType: 'wide',
            gameType: 'quiz',
            quizQuestion: 3,
            game: '03',
        },
        {
            day: 10,
            status: '',
            cellType: 'wide',
            game: '10',
        },
        {
            day: 11,
            status: '',
            cellType: 'tall',
            game: '11',
        },
        {
            day: 12,
            status: '',
            cellType: 'wide',
            gameType: 'quiz',
            quizQuestion: 4,
            game: '03',
        },
        {
            day: 13,
            status: '',
            cellType: '',
            game: '02',
        },
        {
            day: 14,
            status: '',
            cellType: 'wide',
            game: '05',
        },
        {
            day: 15,
            status: '',
            cellType: 'small',
            gameType: 'quiz',
            quizQuestion: 5,
            game: '03',
        },
        {
            day: 16,
            status: '',
            cellType: 'wide',
            game: '01',
        },
        {
            day: 17,
            status: '',
            cellType: 'tall',
            game: '08',
        },
        {
            day: 18,
            status: '',
            cellType: 'small',
            game: '11',
        },
        {
            day: 19,
            status: '',
            cellType: 'small',
            gameType: 'quiz',
            quizQuestion: 6,
            game: '03',
        },
        {
            day: 20,
            status: '',
            cellType: 'wide',
            game: '07',
        },
        {
            day: 21,
            status: '',
            cellType: '',
            gameType: 'quiz',
            quizQuestion: 7,
            game: '03',
        },
        {
            day: 22,
            status: '',
            cellType: 'wide',
            game: '04',
        },
        {
            day: 23,
            status: '',
            cellType: 'tall',
            gameType: 'quiz',
            quizQuestion: 8,
            game: '03',
        },
        {
            day: 24,
            status: '',
            cellType: '',
            game: '10',
        },
    ],
}

export const calendarDataMobile = {
    data: [
        {
            day: 1,
            status: '',
            cellType: '',
            game: '01',
        },
        {
            day: 2,
            status: '',
            cellType: 'wide',
            game: '02',
        },
        {
            day: 3,
            status: '',
            cellType: 'small',
            gameType: 'quiz',
            quizQuestion: 1,
            game: '03',
        },
        {
            day: 4,
            status: '',
            cellType: 'wide',
            game: '04',
        },
        {
            day: 5,
            status: '',
            cellType: 'small',
            game: '05',
        },
        {
            day: 6,
            status: '',
            cellType: 'tall',
            gameType: 'quiz',
            quizQuestion: 2,
            game: '03',
        },
        {
            day: 7,
            status: '',
            cellType: 'wide',
            game: '07',
        },
        {
            day: 8,
            status: '',
            cellType: 'tall',
            game: '08',
        },
        {
            day: 9,
            status: '',
            cellType: 'wide',
            gameType: 'quiz',
            quizQuestion: 3,
            game: '03',
        },
        {
            day: 10,
            status: '',
            cellType: 'small',
            game: '10',
        },
        {
            day: 11,
            status: '',
            cellType: 'wide',
            game: '11',
        },
        {
            day: 12,
            status: '',
            cellType: 'wide',
            gameType: 'quiz',
            quizQuestion: 4,
            game: '03',
        },
        {
            day: 13,
            status: '',
            cellType: '',
            game: '02',
        },
        {
            day: 14,
            status: '',
            cellType: 'wide',
            game: '05',
        },
        {
            day: 15,
            status: '',
            cellType: 'small',
            gameType: 'quiz',
            quizQuestion: 5,
            game: '03',
        },
        {
            day: 16,
            status: '',
            cellType: 'tall',
            game: '01',
        },
        {
            day: 17,
            status: '',
            cellType: '',
            game: '08',
        },
        {
            day: 18,
            status: '',
            cellType: 'tall',
            game: '11',
        },
        {
            day: 19,
            status: '',
            cellType: 'small',
            gameType: 'quiz',
            quizQuestion: 6,
            game: '03',
        },
        {
            day: 20,
            status: '',
            cellType: 'small',
            game: '07',
        },
        {
            day: 21,
            status: '',
            cellType: '',
            gameType: 'quiz',
            quizQuestion: 7,
            game: '03',
        },
        {
            day: 22,
            status: '',
            cellType: 'small',
            game: '04',
        },
        {
            day: 23,
            status: '',
            cellType: 'small',
            gameType: 'quiz',
            quizQuestion: 8,
            game: '03',
        },
        {
            day: 24,
            status: '',
            cellType: 'wide',
            game: '10',
        },
    ],
}
